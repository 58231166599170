<template>
  <div>
    <Datatable
      ref="orderbonArchiveTable"
      :api-endpoint="ENDPOINTS.DATATABLES.SETTINGS.ORDERBON_ARCHIVE"
      :datatable-headers="datatableHeaders"
      hideOptionsMenu
      :showSelectCheckbox="false"
      show-display-buttons
      @displayEntry="displayEntry"
    />

    <div id="onScreenKeyboardDiv">
      <vue-touch-keyboard
        class="internalWidth"
        id="onScreenKeyboard"
        :options="touchKeyboard.options"
        :defaultKeySet="touchKeyboard.keySet"
        v-if="touchKeyboard.visible"
        :layout="touchKeyboard.layout"
        :cancel="hideTouchKeyboard"
        :accept="hideTouchKeyboard"
        :input="touchKeyboard.input"
      />
    </div>
  </div>
</template>

<script>
import Datatable from "../../../datatable/Datatable";
import { ENDPOINTS } from "@/config";
import mixin from "@/mixins/KeyboardMixIns";

export default {
  name: "OrderbonArchiveComponent",
  components: {
    Datatable,
  },
  mixins: [mixin],
  data() {
    return {
      ENDPOINTS,
      datatableHeaders: [
        {
          text: this.$t("generic.lang_id"),
          align: "left",
          value: "uuid",
          hide: true,
        },
        {
          text: this.$t("generic.lang_time") + " / " + this.$t("generic.lang_date"),
          value: "date",
        },
        {
          text: this.$t("settings.lang_userid"),
          value: "user_id",
        },
        {
          text: this.$t("generic.lang_TableNo"),
          value: "tableNo",
        },
        { text: this.$t("generic.lang_party"), value: "tableParty" },
      ],
    };
  },

  methods: {
    displayEntry(entry) {
      this.$router.push({
        name: "settings.cashierSettings.displayorderbonarchive",
        params: { id: entry.uuid },
      });
    },
  },
};
</script>
